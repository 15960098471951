import React from "react"

// Libraries
import { Link } from "gatsby"

// Components
import VerticalTitle from "components/vertical-title/"

// Icons
import IconMobile from "assets/icons/technology/icon-case-study-mobile.inline.svg"
import IconDesktop from "assets/icons/technology/icon-case-study-desktop.inline.svg"

const CaseStudy = () => {
  const items = [
    {
      title: "01",
      description: (
        <p>
          You have an <b>NDB-powered Electric Vehicle (EV).</b>
        </p>
      ),
    },
    {
      title: "02",
      description: (
        <>
          <p>Your car will have a dedicated profile on the NDB app.</p>
          <br />
          <p>
            You will be able to unlock the energy produced by NDB through the
            VOLT TOKEN.
          </p>
        </>
      ),
    },
    {
      title: "03",
      description: (
        <>
          <p>
            When you are not using your Electric Vehicle, you can connect it to
            the grid.
          </p>
          <br />
          <p>
            Based on the energy given to the infrastructure, you will earn Volta
            tokens.
          </p>
          <br />
          <p>Alternatively, you will also be able to mine tokens.</p>
        </>
      ),
    },
  ]

  return (
    <section id="caseStudy" className="technology__case-study section">
      <div className="container">
        <ul className="sidebar">
          <li>
            <Link to="#tokens">Tokens</Link>
          </li>
          <li>
            <Link to="#caseStudy" className="active">
              Case Study
            </Link>
          </li>
        </ul>

        <div className="w-100 d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-start">
          <VerticalTitle
            title="Ndb Electric vehicle"
            animated
            className="mb-4 mb-md-4"
          />

          <div className="content__desktop">
            <h2 className="case-study__title highlight mb-4 mb-md-5">
              Discover how it works, and start to build tomorrow’s world by our
              side
            </h2>

            <div className="items d-flex justify-content-between">
              {items.map(item => (
                <div>
                  <div
                    className="case-study__item mb-4 mb-md-0"
                    key={item.title}
                  >
                    <h2>{item.title}</h2>
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
            <IconMobile className="desktop__icon d-md-none" />
            <IconDesktop className="desktop__icon d-none d-md-block" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudy
