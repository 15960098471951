import React from "react"

// Libraries
import { Link, useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

// Icons
import IconTokenAllocation from "assets/icons/technology/icon-token-allocation.inline.svg"

const Tokens = () => {
  const {
    dualTokenImage,
    ndbTokenImage,
    voltaTokenImage,
    voltaTokenCoin,
    stakePoolImage,
  } = useStaticQuery(graphql`
    query {
      dualTokenImage: file(
        relativePath: { eq: "technology/dual-tokens-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 494, quality: 100)
        }
      }
      ndbTokenImage: file(
        relativePath: { eq: "technology/ndb-token-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 200, quality: 100)
        }
      }
      voltaTokenImage: file(
        relativePath: { eq: "technology/volta-token-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 574, quality: 100)
        }
      }
      voltaTokenCoin: file(
        relativePath: { eq: "technology/volta-token-coin-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 114, quality: 100)
        }
      }
      stakePoolImage: file(
        relativePath: { eq: "technology/stake-pool-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 218, quality: 100)
        }
      }
    }
  `)

  return (
    <section id="tokens" className="technology__tokens section">
      <div className="container">
        <ul className="sidebar">
          <li>
            <Link to="#tokens" className="active">
              Tokens
            </Link>
          </li>
          <li>
            <Link to="#caseStudy">Case Study</Link>
          </li>
        </ul>

        <div className="d-flex flex-wrap">
          <div className="vertical-title mb-4 mb-md-0">
            <h1 className="highlight">Dual Token</h1>
          </div>

          <div className="tokens__content">
            <div className="dual-tokens">
              <div className="row align-items-center">
                <div className="content col-12 col-md-5 mb-4 mb-md-0">
                  <p>
                    NDB's <b>vision</b> is to deliver clean energy
                    <b>accessibility, alternative</b> sources, and
                    sustainability to the world.
                  </p>
                  <br />
                  <p>
                    NDB and Volta, fixed and variable tokens, respectively, are
                    backing the <b>dual token</b> environment developed by NDB
                    to support our vision.
                  </p>
                  <br />
                  <p>
                    NDB and VOLT TOKENs are interacting freely on the{" "}
                    <b>open market</b>.{" "}
                  </p>
                  <br />
                  <p>
                    NDB token is a demonstration tool for interest in developing
                    energy solutions and technologies inside our ecosystem.
                  </p>
                  <br />
                  <p>
                    VOLT TOKEN is a <b>live payment</b> instrument for energy
                    products.
                  </p>
                </div>

                <div className="image col-12 col-md-7">
                  <GatsbyImage image={getImage(dualTokenImage)} alt="" />
                </div>
              </div>
            </div>

            <div className="ndb-token">
              <div className="row w-100 justify-content-between">
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                  <h2 className="highlight bordered mb-3">NDB Token</h2>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <p>
                        The NDB token is created in the ERC-20 protocol using
                        Ethereum Network; however, transfer plans to other
                        blockchains remain possible.
                      </p>
                      <br />
                      <p>
                        By holding NDB tokens, you contribute to our vision,
                        advancing the development of technologies and solutions.
                      </p>
                      <br />
                      <p>
                        You will be able to change, interact, and transform your
                        NDB token in the open market or inside our ecosystem by
                        staking your NDB tokens inside a pool.
                      </p>

                      <GatsbyImage
                        image={getImage(ndbTokenImage)}
                        className="d-none d-sm-inline-block mt-4"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <p>
                        Our ecosystem consists of pools of partners and
                        licensees.
                      </p>
                      <br />
                      <p>
                        Staking your NDB tokens in a pool contributes to
                        developing the owner's technologies and future products
                        delivered in collaboration with NDB.
                      </p>
                      <br />
                      <p>
                        By staking NDB tokens on the different pools, the
                        stakers can earn dividends as VOLT TOKENs.
                      </p>
                      <br />
                      <p>
                        Based on time and the availability of resources, we plan
                        to expand our ecosystem described in NDB CITY.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sale-model col-12 col-md-5">
                  <div className="feature w-100 mb-4 mb-md-5">
                    <p className="title mb-3 font-weight--700">
                      Token sale model
                    </p>
                    <p className="d-flex justify-content-between mb-1">
                      <span>Token Name:</span>
                      <span className="font-weight--700">NDB</span>
                    </p>
                    <p className="d-flex justify-content-between mb-1">
                      <span>Token Symbol:</span>
                      <span className="font-weight--700">ndb</span>
                    </p>
                    <p className="d-flex justify-content-between mb-1">
                      <span>Total NDB Token Amount:</span>
                      <span className="font-weight--700">1,000,000,000</span>
                    </p>
                    <p className="d-flex justify-content-between mb-1">
                      <span>Emission Rate:</span>
                      <span className="font-weight--700">
                        New Tokens will never be created
                      </span>
                    </p>
                  </div>

                  <div className="feature w-100 mb-4 mb-md-5">
                    <p className="title mb-3 font-weight--700">
                      Purchase price
                    </p>
                    <p className="d-flex justify-content-between mb-1">
                      <span>Nominal value of Coin:</span>
                      <span className="font-weight--700">
                        Decided through private placement
                      </span>
                    </p>
                  </div>

                  <div className="feature w-100 mb-4 mb-md-5">
                    <p className="title mb-3 font-weight--700">
                      Token Allocation
                    </p>
                    <IconTokenAllocation />
                  </div>
                </div>
              </div>
            </div>

            <div className="volta-token ndb-token">
              <div className="row w-100 justify-content-between">
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                  <h2 className="highlight bordered mb-3">VOLT TOKEN</h2>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <p>
                        To achieve this, we have established NDB City, a
                        cross-platform for innovation and business.
                      </p>
                      <br />
                      <p>
                        Our technologies will be at the center of the expansion
                        and VOLT TOKEN will be used to power them.
                      </p>
                      <br />
                      <p>
                        This utility token is a live payment instrument for the
                        energy produced.
                      </p>

                      <div className="d-none d-sm-flex justify-content-end mt-4">
                        <GatsbyImage image={getImage(voltaTokenCoin)} alt="" />{" "}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <p>
                        Using our app, you will be able to unblock NDB's energy
                        and use your products, almost in the same way you are
                        paying your utility bills.
                      </p>
                      <br />
                      <p>
                        You can pay as you consume and earn tokens by selling
                        the energy back to the grid.
                      </p>
                      <br />
                      <p>
                        VOLT TOKEN is scheduled to deploy after the NDB token.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <GatsbyImage
                    image={getImage(voltaTokenImage)}
                    className="mb-4"
                    alt=""
                  />

                  <div className="row justify-content-center">
                    <div className="col-12 col-md-10">
                      <div className="feature w-100 mb-4 mb-md-5">
                        <p className="title mb-3 font-weight--700">
                          Token sale model
                        </p>
                        <p className="d-flex justify-content-between mb-1">
                          <span>Token Name:</span>
                          <span className="font-weight--700">Volt</span>
                        </p>
                        <p className="d-flex justify-content-between mb-1">
                          <span>Token Symbol:</span>
                          <span className="font-weight--700">VOLT</span>
                        </p>
                        <p className="d-flex justify-content-between mb-1">
                          <span>Total VOLT TOKEN Amount:</span>
                          <span className="font-weight--700">1 Trillion</span>
                        </p>
                        <p className="d-flex justify-content-between mb-1">
                          <span>Emission Rate:</span>
                          <span className="font-weight--700">
                            New tokens can be mined
                          </span>
                        </p>
                      </div>

                      <div className="feature w-100 mb-4 mb-md-5">
                        <p className="title mb-3 font-weight--700">
                          Purchase price
                        </p>
                        <p className="d-flex justify-content-between mb-1">
                          <span className="w-50">Nominal value of Coin:</span>
                          <span className="w-50 font-weight--700">
                            Rely on the constant growth of the economy to remain
                            valuable
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="volta-token ndb-token pt-md-0">
              <div className="row w-100 align-items-center justify-content-center">
                <div className="col-12 col-md-3 mb-4 mb-md-0 d-none d-sm-block">
                  <GatsbyImage image={getImage(stakePoolImage)} alt="" />
                </div>
                <div className="col-12 col-md-7">
                  <div className="w-100 row">
                    <div className="col-12 col-sm-6 mb-4 md-sm-0">
                      <h2 className="highlight bordered mb-3">Stake pool</h2>

                      <GatsbyImage
                        image={getImage(stakePoolImage)}
                        className="d-sm-none mb-4"
                        alt=""
                      />

                      <p>
                        NDB pool will work similarly to S&P 500 for this
                        ecosystem. The dynamic reward index of the successful
                        pool will reward the ecosystem.
                      </p>
                      <br />
                      <p>
                        A reliable operator manages each public or private stake
                        pool of NDB tokens. The operator can be NDB, an
                        individual, or a business.
                      </p>
                      <br />
                      <p>
                        A stake pool is a network node with a public address
                        that stakers can contribute to and receive rewards.
                      </p>
                    </div>
                    <div className="col-12 col-sm-6 mb-4 md-sm-0">
                      <h2 className="bordered color--black mb-3">N</h2>
                      <p>
                        Being part of a pool will give you access to unique
                        private benefits or VOLT TOKENs for your Smart Energy
                        subscription and products.
                      </p>
                      <br />
                      <p>
                        Stake pools can be created to support projects that
                        benefit technology development and the NDB token
                        holders.
                      </p>
                      <br />
                      <p>
                        The pool's participants will get a reward proportionally
                        to their staked amount for each transaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="terms-and-conditions ndb-token pt-md-0">
              <div className="content">
                <p>
                  <b>Terms & Conditions</b>
                </p>
                <br />
                <p>
                  Tokens are neither security nor the stocks of the corporation.
                </p>
                <br />
                <p>
                  The tokens will be available for anyone who agrees to the{" "}
                  <a href="/" className="color-hover--green font-weight--700">
                    Terms and Conditions
                  </a>
                  .
                </p>
                <br />
                <p>
                  This smart contract allows clients to buy tokens in the open
                  market and speculates upon the price.
                </p>
                <br />
                <p>
                  The rejection of the{" "}
                  <a href="/" className="color-hover--green font-weight--700">
                    Terms and Conditions
                  </a>{" "}
                  will exclude the holder from participating in staking pools
                  and interacting with the corporate's services and products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Tokens
