import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

// Sections
import Tokens from "sections/technology/tokens"
import CaseStudy from "sections/technology/case-study"

const Technology = () => (
  <Layout>
    <SEO title="Technology | NDB" />
    <Tokens />
    <CaseStudy />
  </Layout>
)

export default Technology
